var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "model-select" },
    [
      _c("en-select", {
        attrs: {
          placeholder: _vm.defaultParams.placeholder || "请选择",
          "check-mode": "normal",
          mode: _vm.defaultParams.selectMode,
          data: _vm.options,
          props: _vm.defaultProps,
          disabled: _vm.defaultParams.disabled,
          filterable: _vm.defaultParams.filterable,
          "filter-node-method": _vm.filterNodeMethod,
          align: "left",
          "multiple-limit": _vm.defaultParams.multipleLimit,
          "thum-tag": true,
          "data-mode": "data",
          "page-size": _vm.defaultParams.page ? _vm.defaultParams.pageSize : 0,
          "show-assist": _vm.defaultParams.assistFloat && !!_vm.showText.fields,
          multiple: _vm.defaultParams.isMulti,
          clearable: _vm.defaultParams.isMulti,
          lazy: _vm.defaultParams.lazy,
          load: _vm.treeLoad,
          loading: _vm.loading,
          "allow-visible": _vm.canOpenSelect,
          remote: _vm.defaultParams.remote,
        },
        on: {
          change: _vm.onChange,
          "visible-change": _vm.visibleChange,
          "not-allow-visible": _vm.onNotAllowVisible,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function ({ node, data }) {
                return [
                  _vm._t(
                    "default",
                    function () {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "el-tree-node__label",
                                style: { color: node.color },
                                attrs: { title: node.title },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      typeof _vm.defaultProps.label ===
                                        "function"
                                        ? _vm.defaultProps.label(data)
                                        : data[_vm.defaultProps.label]
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(
                              !_vm.defaultParams.assistFloat
                                ? _vm.showText.fields
                                : [],
                              function (item, i) {
                                return _c(
                                  "span",
                                  { key: i, staticClass: "en-node-assist" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.fieldName) +
                                        ":" +
                                        _vm._s(data[item.field]) +
                                        " "
                                    ),
                                    i !== _vm.showText.fields.length - 1
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.showText.split)),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                    { data: data, node: node }
                  ),
                ]
              },
            },
            {
              key: "tags",
              fn: function ({ showSelected, info }) {
                return [
                  _c(
                    "div",
                    { key: 1 },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "300",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "sculpture-list",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _vm._l(
                                _vm.selectValue.slice(0, 3),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "sculpture-style",
                                    },
                                    [
                                      index < 3
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s([...item.name][0] || "")
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              _vm.selectValue.length >= 3
                                ? _c(
                                    "div",
                                    { staticClass: "sculpture-style-other" },
                                    [
                                      _vm._v(
                                        " +" +
                                          _vm._s(_vm.selectValue.length - 3) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._l(showSelected, function (item) {
                            return _c(
                              "el-tag",
                              {
                                key: item.value,
                                staticClass: "en-select-tag",
                                attrs: {
                                  closable: !info.selectDisabled,
                                  size: info.collapseTagSize,
                                  hit: item.hitState,
                                  type: "info",
                                  "disable-transitions": "",
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.deleteTag($event, item)
                                  },
                                },
                              },
                              [
                                info.thumTag
                                  ? [
                                      item.node && item.node.thum
                                        ? _c("img", {
                                            staticClass:
                                              "en-select__tags-thum en-select__tags-thum-image",
                                            attrs: { src: item.node.thum },
                                          })
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "en-select__tags-thum en-select__tags-thum-text",
                                              style: {
                                                "background-color":
                                                  item.node &&
                                                  item.node.thumBackgroundColor
                                                    ? item.node
                                                        .thumBackgroundColor
                                                    : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.node &&
                                                      item.node.thumText
                                                      ? item.node.thumText
                                                      : item.currentLabel.substr(
                                                          0,
                                                          1
                                                        )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-select__tags-text",
                                    style: {
                                      color:
                                        item.node && item.node.color
                                          ? item.node.color
                                          : "",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.currentLabel))]
                                ),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "assist",
              fn: function ({ data }) {
                return [
                  _c(
                    "span",
                    { staticClass: "custom-assist", attrs: { data: data } },
                    _vm._l(_vm.showText.fields, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "assist-block" },
                        [
                          data[item.field]
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(item.fieldName) +
                                    ": " +
                                    _vm._s(data[item.field])
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectValue,
          callback: function ($$v) {
            _vm.selectValue = $$v
          },
          expression: "selectValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }