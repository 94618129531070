<!--
 * @Descripttion: 单选下拉框 基础&tree树 模式
 * @Author: 彭博
 * @Date: 2020-08-05 09:46:20
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-19 09:52:37
-->
<!--
  * config 配置参数总览
  * demo页路由地址  /example/select
  * searchKeys: ["name", "any"], // 自定义搜索keyword匹配key值  非必传
  * isMulti: Boolean, // 是否多选 默认false  非必传
  * page: Boolean, // 是否开启分页 默认false  非必传 关联参数 pageSize
  * showText: {fields:[{field:"name",fieldName:"名称"},{field:"any",fieldName:"编号"}],split:"--"},, // 显示辅助字段 split为多字段连接处显示  非必传
  * assistFloat: Boolean // 开启辅助字段悬浮显示  showText 如果内容太多  请设置此选项为true 默认false  非必传
  * selectable: Object 可选字段匹配  例：{type: "003"} 则显示数据中具有type属性 且 type对应值为"003"可以被选中 其他无法选中
  * lazy: Boolean 是否懒加载 默认为true  dependData.dataSource == 11状态自定义数据默认设置false
  * defaultProps: Object   参考en-tree 配置结构点label value等
  * choiceRule: Boolean  是否勾选父项 （defaultProps.children配置项对应数据的长度大于0 组件识别为父项  另外hasChildren === "000" 识别为父项）
  * selectMode 可选值 normal tree   默认tree
  * pageSize: Number  分页参数 默认20
  * placeholder  同字面意义
  * disabled: Boolean  同字面意义
  * multipleLimit: Number 多选数量  默认0 不限制
  * filterable: Boolean 是否开启搜索  默认true开启
  * 下面dependData为select请求或静态设置options依赖数据  必传
  * dependData: Obejct
  *   {
  *      //根据业务场景有几种常用的情况
  *     commType: 1:int  通用人员树
  *                2:int  通用组织机构树
  *
  *     //基础数据  (在业务建模中 均为字段属性)
  *     areaObjType: 002  人员数
  *     areaObjType: 014(会计期间) 016(会计科目) 100(纳税主体的字段) 这3种情况有单独的接口
  *     areaObjType: ???  其它非上述基础数据 都是通过相同的接口进行查询
  *     ids: "",  //指定数据范围
  *     deptType: "",
  *
  *     dataSource: 10,  省市区树形数据   当前为本地存储静态数据
  *
  *     dataSource: 11, 表示无需请求接口  去dataList中塞入自定义数据
  *     dataList: [],  静态数据  ！！dataSource设置为11才可生效
  *
  *      //特殊处理银行卡，城市信息
  *     field: bank(银行卡信息) city(城市信息),  当前为本地存储静态数据
  *
  *     下面3个request相关值 需组合使用  自定义接口服务时传入
  *     requestService: 服务需引入api中对应接口服务配置    参考demo页
  *     requestFn: 引入服务中对应接口    参考demo页
  *     requestParams: 接口传参报文    参考demo页
  *
  *      //当为单选自定义基础数据时，无areaObjType等 需要通过字段和模板id去查询  自定义的数据项
  *      templateId: "", //模板id
  *      field: ""       //字段

  *     具体参数配置请查看en-transfer-data/matchRequestConfig.js  参考老版本逻辑
  *  }
-->
<template>
  <div class="model-select">
    <!-- 单选模式 check-mode固定为：normal模式   siblings模式只允许勾选同子集选项--->
    <en-select
      v-model="selectValue"
      :placeholder="defaultParams.placeholder || '请选择'"
      :check-mode="'normal'"
      :mode="defaultParams.selectMode"
      :data="options"
      :props="defaultProps"
      :disabled="defaultParams.disabled"
      :filterable="defaultParams.filterable"
      :filter-node-method="filterNodeMethod"
      align="left"
      :multiple-limit="defaultParams.multipleLimit"
      :thum-tag="true"
      @change="onChange"
      @visible-change="visibleChange"
      data-mode="data"
      :page-size="defaultParams.page ? defaultParams.pageSize : 0"
      :show-assist="defaultParams.assistFloat && !!showText.fields"
      :multiple="defaultParams.isMulti"
      :clearable="defaultParams.isMulti"
      :lazy="defaultParams.lazy"
      :load="treeLoad"
      :loading="loading"
      :allow-visible="canOpenSelect"
      :remote="defaultParams.remote"
      @not-allow-visible="onNotAllowVisible"
    >
      <template #content="{ node, data }">
        <slot  :data="data" :node="node">
          <span>
            <span class="el-tree-node__label" :title="node.title" :style="{ color: node.color }">
              {{ typeof defaultProps.label === "function" ? defaultProps.label(data) : data[defaultProps.label] }}
            </span>
            <span class="en-node-assist" v-for="(item,i) in (!defaultParams.assistFloat ? showText.fields : [])" :key="i">
              {{item.fieldName}}:{{data[item.field]}}
              <span v-if="i !== showText.fields.length-1">{{showText.split}}</span>
            </span>
          </span>
        </slot>
      </template>
      <template #tags="{showSelected,info}">
            <div :key="1">
              <el-popover
                placement="bottom"
                width="300"
                trigger="hover">
                  <div class="sculpture-list" slot="reference">
                    <div v-for="(item, index) in selectValue.slice(0,3)" :key="index" class="sculpture-style"  >
                      <span v-if="index < 3">{{[...item.name][0] || ""}}</span>
                    </div>
                    <div v-if="selectValue.length >= 3" class="sculpture-style-other">
                      +{{selectValue.length - 3}}
                    </div>
                  </div>
                  <el-tag
                      v-for="item in showSelected"
                      :key="item.value"
                      :closable="!info.selectDisabled"
                      :size="info.collapseTagSize"
                      :hit="item.hitState"
                      type="info"
                      @close="deleteTag($event, item)"
                      disable-transitions
                      class="en-select-tag"
                    >
                      <template v-if="info.thumTag">
                        <img
                          :src="item.node.thum"
                          class="en-select__tags-thum en-select__tags-thum-image"
                          v-if="item.node && item.node.thum"
                        />
                        <span
                          class="en-select__tags-thum en-select__tags-thum-text"
                          :style="{
                            'background-color':
                              item.node && item.node.thumBackgroundColor
                                ? item.node.thumBackgroundColor
                                : ''
                          }"
                          v-else
                        >
                          {{
                            item.node && item.node.thumText
                              ? item.node.thumText
                              : item.currentLabel.substr(0, 1)
                          }}
                        </span>
                      </template>
                      <span
                        class="el-select__tags-text"
                        :style="{
                          color: item.node && item.node.color ? item.node.color : ''
                        }"
                      >{{ item.currentLabel }}</span
                      >
                  </el-tag>
              </el-popover>
            </div>
        </template>
      <!-- 辅助项 slot -->
      <template #assist="{ data }">
        <span class="custom-assist" :data="data">
          <span class="assist-block" v-for="(item, index) in showText.fields" :key="index">
            <span v-if="data[item.field]">{{ item.fieldName }}: {{ data[item.field] }}</span>
          </span>
        </span>
      </template>

    </en-select>
  </div>
</template>

<script>
import { transferService } from "../en-transfer-data/TransferService";
import { matchConfig } from "../en-transfer-data/matchRequestConfig";

export default {
  name: "EnSelectData",
  model: {
    prop: "modelValue",
    event: "update:modelValue"
  },
  props: {
    config: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    modelValue: {
      required: true
    }
  },
  components: {},
  computed: {
    // 是否tree树
    isTree() {
      return this.defaultParams.selectMode === "tree";
    },
    // 能否展开select
    canOpenSelect() {
      return true;
    },
    showText() {
      return {
        fields: this.defaultParams.showText?.fields || [],
        split: this.defaultParams.showText?.split || ""
      };
    }
  },
  data() {
    return {
      selectValue: this.modelValue,
      defaultParams: {
        searchKeys: ["name"], // 搜索关键字 对应filter的key
        page: false, // 是否分页
        placeholder: "搜索关键词",
        pageSize: 20, // 默认20,
        pageNo: 0, // 默认0,
        selectMode: "tree", // 下拉框类型  // 当前设置 自定义:normal  其他：tree
        choiceRule: true, // 是否勾选父项
        disabled: false, // disabled
        multipleLimit: 0, // 多选数量
        isMulti: false, // 是否多选
        lazy: true,
        filterable: true, // 开启搜索
        assistFloat: false, // 开启辅助自动显示   如果内容太多  请设置此选项为true
        remote: false, // 是否为远程搜索
        selectable: {}, // { type: "001" } type=== 001 可选
        dependData: {}
      },
      loading: false,
      options: [],
      // showText: {fields:[{field:"name",fieldName:"名称"},{field:"any",fieldName:"编号"}],split:""},
      openSelect: false,
      defaultProps: {
        label: "name",
        value: "id",
        isLeaf: "leaf",
        children: "children"
      }
    };
  },
  watch: {
    // 监听 props变化
    "config.defaultProps": {
      deep: true,
      handler(value) {
        Object.assign(this.defaultProps, value);
      }
    },
    // dependData 监听 改变重新init
    "config.dependData": {
      async handler(value) {
        console.log("监听 dependData", value);
        this.defaultParams.dependData = value;
        this.options = await this.initSelectOptions();
      }
    },
    modelValue(value) {
      console.log("watch modelValue", value);
      this.selectValue = value; // 监听model数据变化
    }
  },
  methods: {
    // 关键字搜索   支持配置查询key
    filterNodeMethod(keywords, values) {
      if (!keywords) return values;
      return this.defaultParams.searchKeys.find((key) => values[key] !== undefined && values[key].toString().indexOf(keywords) !== -1);
    },
    // 多选清除
    clearMultiSelect() {
      this.selectValue = "";
    },
    // 不允许展开下拉框
    onNotAllowVisible() {
      this.$message("不允许展开");
    },
    onChange() {
      // 更新值
      console.log("select change", this.selectValue);
      this.$emit("update:modelValue", this.selectValue);
    },
    /**
     * select 显示隐藏
     * params {Boolean} bool true->显示
     */
    async visibleChange(bool) {
      this.openSelect = bool;
      if (this.options.length) return;
      bool
        && this.defaultParams.page
        && this.defaultParams.selectMode !== this.config.selectMode
        && (this.defaultParams.selectMode = this.config.selectMode);
      // 走treeNode
      if (!bool || this.defaultParams.page || this.options.length > 0) return;
      this.options = await this.initSelectOptions();
    },
    // tree树 加载子集数据
    async treeLoad(node, resolve) {
      let data;
      if (this.defaultParams.selectMode === "tree") {
        const parentId = node.data?.id;
        if (this.openSelect && this.canInit()) {
          this.defaultParams.page && (this.defaultParams.pageNo = node.pageNo + 1); // 开启分页  计算当前页数
          const options = await this.initSelectOptions(parentId);
          if (this.defaultParams.page) {
            data = {
              total: options.length === this.defaultParams.pageSize ? 600 : 0,
              pageNo: node.pageNo + 1,
              datas: options
            };
          } else {
            data = options;
          }
        }
        resolve(data || []);
      } else {
        try {
          const { requestService, requestFn, requestParams } = this.defaultParams.dependData;
          requestParams.pageSize = requestParams.pageSize || this.defaultParams.pageSize;
          if (node) { // 加载更多
            requestParams.pageSize += (this.defaultParams.pageSize);
          }
          if (requestService[requestFn]) {
            data = await requestService[requestFn](requestParams);
          } else {
            data = await requestService.post(requestFn, requestParams);
          }
          resolve(data);
        } catch (error) {
          resolve([]);
        }
      }
    },
    // 请求 select options
    async getSelectOptions(parentId = null) {
      const defaultParams = this.defaultParams;
      if (this.defaultParams.dependData?.requestService) {
        return this.formatList(await this.customRequest(parentId), true);
      }
      const config = matchConfig(this.defaultParams.dependData);
      // 配置参数未设置默认参数时 设置默认展示key
      !this.defaultParams.defaultProps && (this.defaultProps = Object.assign(this.defaultProps, config.treeParams));
      if (!config.reqStr) {
        this.defaultParams.lazy = false; // 静态数据移除 懒加载
        return this.formatList(config.dataList || [], config.hasChildren);
      }
      const params = { ...config.reqData };
      // 分页参数为真 追加分页参数
      defaultParams.pageSize && (params.pageSize = defaultParams.pageSize);
      defaultParams.pageNo && (params.pageNo = defaultParams.pageNo);
      // 追加子集查询参数
      parentId && (params.id = parentId);
      const res = await transferService[config.reqStr](params);
      // this.modeType = "normal";
      return this.formatList(res || [], config.hasChildren);
    },
    async customRequest(parentId) {
      const { requestService, requestParams, requestFn } = this.defaultParams.dependData;
      parentId && (requestParams.id = parentId);
      const result = await requestService[requestFn](requestParams);
      return result;
    },
    filterDataList(list = []) {
      const filterObj = this.defaultParams.filterObj;
      if (!filterObj) return list;
      return list.filter((item) => this.matchKey(item, filterObj));
    },
    // 遍历 filter规则 有任意条件不符合规则  返回false
    matchKey(obj = {}, keyObj) {
      let status = true;
      if (this.defaultParams.modeType === "tree" && obj.hasChildren !== "000") {
        Object.keys(keyObj).forEach((key) => {
          if (obj[key] !== keyObj[key]) status = false;
        });
      }
      return status;
    },
    // 格式化列表
    formatList(list = [], bool) {
      list = this.filterDataList(list);
      return this.mapFill(list, bool);
    },
    // 填充 组件所需属性
    // 塞入 hasChildren = 001
    mapFill(list = [], bool) {
      return list.map((opt) => {
        const obj = {
          disabled: this.itemIsDisabled(opt),
          // !bool 为真表示叶子
          leaf: (!bool || opt.hasChildren !== "000") && !opt[this.defaultProps.children]?.length,
          [this.defaultProps.children]: this.formatList(opt[this.defaultProps.children], bool)
        };
        console.log(111, obj.leaf);
        // bool === true  没有children 用于树判断
        if (!bool) obj.hasChildren = "001";
        return Object.assign(opt, obj);
      });
    },
    async initSelectOptions(parentId = null) {
      if (this.defaultParams.selectMode === "normal" && this.defaultParams.lazy) {
        return [];
      }
      // 第一级数据请求 重置options
      if (!parentId) {
        this.options = [];
        this.loading = true;
      }
      // !parentId && (this.options = [], this.loading = true);
      const options = await this.getSelectOptions(parentId);
      console.log(111, options);
      !parentId && (this.loading = false);
      return options;
    },
    // 判断当前item 是否disabled
    itemIsDisabled(item = {}) {
      const selectable = this.defaultParams.selectable; // 根据配置查询当前是否可选
      let selectStatus = false;
      try {
        selectStatus = Object.keys(selectable).find((key) => item[key] !== selectable[key]);
      } catch {
        throw new Error("config.selectable 类型必须为 Object");
      }
      if (selectStatus) return true;
      // 配置了不允许勾选父项
      if (!this.defaultParams.choiceRule) {
        return item[this.defaultProps.children]?.length || item.hasChildren === "000";
      }
      return false;
    },
    // 判断是否需要init
    canInit() {
      // 暂时保留 后续补充
      return true;
    }
  },
  created() {
    // 模板参数暂未明白 以下为临时自定义数据
    // 配置中允许 新增条目 则 filterable = true
    // allowCreate === true ==> filterable=true
    // 默认normal   tree树展开下拉框时切换
    console.log(this.modelValue, this.selectValue);
    Object.assign(this.defaultParams, this.config);
    this.config.defaultProps && Object.assign(this.defaultProps, this.config.defaultProps);
    // tree树 点击唤起后转换为tree  因tree树默认装载即请求 特殊处理
    // this.defaultParams.selectMode = this.defaultParams.page ? "normal" : this.defaultParams.selectMode;
  }
};
</script>

<style scoped lang="scss">
.model-select {
  display: flex;
  align-items: center;
}
.el-select {
  /*width: calc(100% - 36px);*/
  width: 100%;
}

.el-select-dropdown {
  .el-tree-node__label {
    // margin-right: 20px;
    font-size: 12px;
  }
}

.custom-assist {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  .assist-block {
    margin-right: 20px;
  }
}
.sculpture-list{
  margin-left:5px;
  width: 80px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  .sculpture-style-other{
    margin-left:-5px;
    width: 22px;
    height: 22px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #B4BED8;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .sculpture-style{
    margin-left:-5px;
    width: 22px;
    height: 22px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.name-style{
  width: 78px;
  height: 24px;
  background: #F5F8FC;
  border: 1px solid #DCE5EC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .name-sculpture{
    margin-left:4px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .name-text{
    margin-left:4px;
    width: 46px;
    height: 13px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1A1C1E;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.en-select__tags-thum {
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      color: #ffffff;
      overflow: hidden;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      margin-right: 4px;
      position: relative;
      top: -1px;
      background-color: #3e90fe;
      font-size: 12px;
    }
.en-select-tag{
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
